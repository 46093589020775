
































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
    InsBanner: () => import('@/components/base/InsBanner.vue'),
    InsAboutUs: () => import('@/components/business/home/InsAboutUs.vue'),
    InsPresident: () => import('@/components/business/home/InsPresident.vue'),
    InsLogoGather: () => import('@/components/business/home/InsLogoGather.vue'),
    CmxCmsList: () => import('@/components/business/home/InsCmsList.vue'),
    CmxLatestNews: () => import('@/components/business/home/InsLatestNews.vue'),
    InsCalendar: () => import('@/components/business/home/InsCalendar.vue'),
    InsFullCalendar: () => import('@/components/business/home/InsFullCalendar.vue'),
    InsBaiduMap: () => import('@/components/business/promotion/InsBaiduMap.vue'),
    InsPromotion: () => import('@/components/business/home/InsPromotion.vue')
  }
})
export default class InsHome extends Vue {
  // swiperOption: object = {
  //   loop: true,
  //   autoplay: false,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true
  //   },
  //   autoHeight: true, // 高度随内容变化
  //   observer: true, // 修改swiper自己或子元素时，自动初始化swiper
  //   observeParents: true // 修改swiper的父元素时，自动初始化swiper
  // };

  get AreaCode () {
    return localStorage.getItem('AreaCode') || '';
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {

  }
}
